import React from 'react';
import { List, Popper, ClickAwayListener, Fade,
				MenuItem, Menu, TextField, makeStyles,
				InputBase } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import SearchIcon from '@material-ui/icons/Search';

import { SelectBox } from 'devextreme-react/select-box';

const myStyles = makeStyles((theme) => ({
	popperList: {
		/*width: '20ch',*/
		zIndex: 999
	},
	list: {
		//width: '186px',
		width: '200px',
		maxHeight: 300,
		overflowY: 'auto',
		//maxWidth: '25ch',
		borderTop: '0px solid rgb(170, 170, 170)',
		padding: 0,
		backgroundColor: theme.palette.primary.main,
		borderRadius: '0 0 4px 4px',
		boxShadow: '0px 2px 10px #0004',
    [theme.breakpoints.down('sm')]: {
			width: 180,
		},
    [theme.breakpoints.down('xs')]: {
      width: 144,
    },
		'&>li':{
      backgroundColor: '#fff1',
			'&:hover': {
      	backgroundColor: '#fff2',
    	},
		}
	},
	searchBox: {
		//width: 'min-content',
		width: '200px',
		//maxWidth: '25ch',
		marginBlock: '8px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff1',
    '&:hover': {
      backgroundColor: '#fff2',
    },
    [theme.breakpoints.down('sm')]: {
			width: 180,
			marginBlock: '4px',
      /*marginLeft: theme.spacing(1),*/
		},
    [theme.breakpoints.down('xs')]: {
      width: 144,
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
			scale: 0.7,
			padding: '0 8px',
		},
  },
  inputRoot: {
    color: 'inherit',
		width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
		fontSize: 12,
    [theme.breakpoints.down('sm')]: {
			fontSize: 10,
		},
    /*[theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },*/
  },
	menuItem: {
		'&[data-title]:hover:after': {
		  content: 'attr(data-title)',
		  position: 'absolute',
			background: '#fff4',
			color: '#ffd8cc',
			borderRadius: '6px',
			padding: '4px 8px',
			fontSize: '0.8rem',
		  top: 0,
		  left: '50%',
			transform: 'translate(-50%, 80%)',
			zIndex: 15,
		}
	},
}))

const ShipsSearchSelector = (props) => {
	const classes = myStyles();
  const inputReference = React.useRef();
	const {ships, handleChangeShip, position} = props;
	const [selectedShip, setSelectedShip] = React.useState(props.imo)
	const [searching, setSearching] = React.useState(false)

	const [searched, setSearched] = React.useState('')
	const [menu, setMenu] = React.useState()
	const [openMenu, setOpenMenu] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);

	let timer = 0;
	const searchValue = (value) => {
		if(value!==searched) 
			setSearching(true)

		if(value==='') setSearched(value)
		if(timer) clearTimeout(timer);
		//console.log("Search value", value)
		setSearched(s => value)
	}

	const handleListClick = (ev, value) => {
		console.log("handleListClick", value)
		//console.log("handleListClick", ev.target.value)
		setOpenMenu(false)
		handleChangeShip(value)
		init()
	}

	const setMenuList = (values) => {
		let out=[];
		values.forEach(v =>{
			//console.log('setMenuList', v.imo)
			//console.log('setMenuList', selectedShip)
			const title = `${v.name} (${v.imo})`
			if(v.imo == selectedShip)
				out.push(
					<MenuItem
						className={classes.menuItem}
						style={{color: '#50a0dc'}}
						key={v.imo}
						value={`${v.imo}`}
						/*data-title={title}*/
						onClick={(ev) => handleListClick(ev, v.imo)}>
							{title}
					</MenuItem>
				)
			else
				out.push(
					<MenuItem
						className={classes.menuItem}
						key={v.imo}
						value={`${v.imo}`}
						/*data-title={title}*/
						onClick={(ev) => handleListClick(ev, v.imo)}>
							{title}
					</MenuItem>
				)
		})
		/*if(out){
			setOpenMenu(true)
			setMenu(out)
		}else{
			setOpenMenu(false)
		}*/
		if(out.length===0){
			out.push(<MenuItem key='undefined' value={undefined} disabled>Not found</MenuItem>)
		}
		setOpenMenu(true)
		setMenu(out)
	}

	let tim=undefined

	const handleClick = (event) => {
		//console.log("handle click", event.target)
    setAnchorEl(event.currentTarget);
		if(!openMenu){
			setMenuList(ships)
			setOpenMenu(true)
		}
		clearTimeout(tim)
  };

  const handleClose = () => {
console.log("handleClose", anchorEl)
		tim = setTimeout(() => {
			setAnchorEl(false)
			setOpenMenu(false);
			init()
			setSearching(false)
		}, 300);
  };

	const getShips = (search) => {
		if(!ships) return []
		search = search.toLowerCase();
		return ships.filter(ship => {
			const searchable = (`${ship.name} (${ship.imo})`).toLowerCase();
			if(searchable.includes(search)){
				return ship;
			}
		});
	}

	const init = () => {
		if(ships){
			const ship = getShips(selectedShip)[0];
			let v = '';
			if(ship){
				v = `${ship.name} (${ship.imo})`;
			}
			console.log('defaultValue', v)
			setSearched(v)
		}
	}

	React.useEffect(() => {
		if(openMenu){
			if(searched && searching){
				timer = setTimeout(() => {
					if(!ships) return [];
					let found = getShips(searched);
					//console.log('Found', found)
					setMenuList(found)
				}, 450)
			}else{
				setMenuList(ships)
			}
		}
	}, [searched, openMenu, searching])

	React.useEffect(() => {
		init()
	}, [])

	return (
		<div className={classes.searchBox}>
		<div style={{position: 'relative'}}>
    	<div className={classes.searchIcon}>
      	<SearchIcon />
      </div>
      <InputBase
				onClick={handleClick}
    	  placeholder="Search ship…"
				inputRef={anchorEl}
				value={searched}
        classes={{
        	root: classes.inputRoot,
        	input: classes.inputInput,
      	}}
				onChange={(e) => searchValue(e.target.value)}
      	inputProps={{ 'aria-label': 'search' }}
      />
    </div>
		{menu && openMenu &&
			(<ClickAwayListener
			  mouseEvent="onMouseDown"
			  touchEvent="onTouchStart"
			  onClickAway={handleClose}
				>
				<Popper
					open={openMenu} anchorEl={anchorEl} transition
					placement={`bottom-start`}
					className={classes.popperList}
				>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
						<List className={classes.list}>
							{menu}
						</List>
          </Fade>
        )}
      </Popper>
			</ClickAwayListener>)
		}
		</div>
	);

}

export default ShipsSearchSelector;
