import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import themeFactory from 'utils/theme-factory.js';


const ThemeProviderComponent = ({ children, payload }) => {
  let theme = undefined;
  
  if (payload && payload.settings && payload.settings.theme) {
    theme = themeFactory[payload.settings.theme];
  }
  
  if (!theme) {
    theme = themeFactory[themeFactory['default']];
  }
  
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

export default ThemeProviderComponent;
