import React from 'react';
import FooterView from './footer-view.js'

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  render() {
    return (
      <FooterView />
    )
  }
}

Footer.defaultProps = {
}

export default Footer;
