import { connect } from 'react-redux';
import { Ship, User } from 'actions';
import AppView from './app-view.js';
import {fleet_data} from 'utils/test_data_2.js';

function getUserDetails(dispatch, auth, imo) {
  if (!auth || !auth.token) return;
  dispatch(User.askUser(auth.token, imo));
}

function getPayload(dispatch, auth, imo) {
  if (!auth || !auth.token || !imo) return;
  dispatch(User.askPayload(auth.token, imo));
}

function setImo(dispatch, imo) {
  dispatch(Ship.setImo(imo));
}

function setShips(dispatch, auth, props){
  if (!auth || !auth.token) return;
  User.volatileApi(auth.token, 'ships/imo').then(data => {
		if(data && data.data){
    	let ships = Object.values(data.data)
    	ships.sort((a, b) => a.name.localeCompare(b.name));
			/*let existsShipImo=false;
			if(props.state.shipImo){
				existsShipImo = ships.filter(ship => ship.imo===props.state.shipImo).length>0
				console.log('exists ship', existsShipImo)
			}*/
    	dispatch(Ship.setShips(ships))
    	/*if(ships.length>0 && !existsShipImo){
      	let imo = ships[0].imo;
      	dispatch(Ship.setImo(imo))
    	}*/
    }
  })
}

function setFleets(dispatch, auth, props){
  if (!auth || !auth.token) return;

	  User.volatileApi(auth.token, 'fleets/ids').then(data => {
			if(data && data.data){
				let fleets = Object.values(data.data);
				fleets.sort((a, b) => a.name.localeCompare(b.name));
				dispatch(Ship.setFleets(fleets))
				console.log("props state fleet", props.state.fleet)
				//cercare dentro fleets se esiste la fleet selezionata
				let fleet=false;
				if(props.state.fleet){
					fleet = fleets.find(fleet => fleet.id===props.state.fleet)
				}
				if(fleets.length>0){
					//se non viene trovato la flotta
					//allora seleziona la prima della lista
		    	if(!fleet){
						let id = fleets[0].id;
						console.log("FLEET ID", id)
						dispatch(Ship.setFleet(id));
					}
					const existsShipImo = props.state.shipImo ? fleet.ships.includes(props.state.shipImo) : false;
					//se la nave precedentemente selezionata non fa parte della flotta attualmente selezionata
					//allora seleziona la prima della lista
					if(!existsShipImo){
						//set first ship of the fleet as selected ship
						let imo = fleets[0].ships[0];
						dispatch(Ship.setImo(imo))
					}
				}
			}
		}).catch(err => console.log(err))
}

const setFleetFrame = (dispatch, auth, fleet_id) => {
  if (!auth || !auth.token) return;

	dispatch(Ship.setFleetFrame({pending: true}))

	//TEST
	//TO-REMOVE
	//dispatch(Ship.setFleetFrame({ [fleet_id] : fleet_data.data}))
	//return

	User.volatileApi(auth.token, `fleetframe/${fleet_id}`).then(data => {
		console.log(data);
		const frame = data.data?.data;
		console.log(frame);
		if(frame){
			dispatch(Ship.setFleetFrame({ [fleet_id] : frame}))
		}else{
			dispatch(Ship.setFleetFrame(data))
			console.log('Fleet frame: ', data);
		}
	}).catch(err => {
		dispatch(Ship.setFleetFrame({ error : err}))
		console.error('Fleet frame: ', err);
	})
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.authentication,
  payload: state.payload,
	imo: state.shipImo ?? ownProps.state.shipImo,
	ships: state.ships ?? ownProps.ships ?? ownProps.state.ships,
	fleet: state.fleet ?? ownProps.state.fleet,
	fleets: state.fleets ?? ownProps.fleets ?? ownProps.state.fleets,
	user: state.user ?? ownProps.user ?? ownProps.state.user,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  askPayload: (auth, imo) => { getPayload(dispatch, auth, imo) },
  setImo: (imo) => { setImo(dispatch, imo) },
	getShips: (auth) => { setShips(dispatch, auth, ownProps) },
	getFleets: (auth) => { setFleets(dispatch, auth, ownProps) },
	setFleet: (fleet_id) => dispatch(Ship.setFleet(fleet_id)),
	setFleetFrame: (auth, fleet_id) => setFleetFrame(dispatch, auth, fleet_id),
	askUser: (auth, imo) => { getUserDetails(dispatch, auth, imo) },
	initFrame: () => dispatch(Ship.setFrame({})),
})

const App = connect(mapStateToProps, mapDispatchToProps
)(AppView);

export default App;
