import React from "react";
import clsx from 'clsx';
import CircleLoader from 'react-spinners/CircleLoader';
import { makeStyles } from "@material-ui/core/styles";
import { fade } from '@material-ui/core/styles/colorManipulator';


const useStyles = makeStyles(theme => ({
  loader_full: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999999,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& > div > div:nth-child(1)': {
      border: '1px solid #0053A0',
    },
    '& > div > div:nth-child(3)': {
      border: '1px solid #0053A0',
    },
    '& > div > div:nth-child(5)': {
      border: '1px solid #0053A0',
    },
    '& > div': {
      animation: '$spin 2s linear infinite',
    },
  },
  loader: {
		marginTop: '112px',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 999999,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    '& > div > div:nth-child(1)': {
      border: '1px solid #0053A0',
    },
    '& > div > div:nth-child(3)': {
      border: '1px solid #0053A0',
    },
    '& > div > div:nth-child(5)': {
      border: '1px solid #0053A0',
    },
    '& > div': {
      animation: '$spin 2s linear infinite',
    },
  },
  overlay: {
    //backgroundColor: fade(theme.palette.primary.dark, 0.8),
  },
  '@keyframes spin': {
    from: {'transform': 'rotate(0deg)'},
    to: {'transform': 'rotate(360deg)'},
  },
}));

const Loader = ({ size, color, message, overlay, fullScreen }) => {
  const classes = useStyles();

	if(fullScreen){
  	return (
    	<div className={clsx({[classes.loader_full]: true, [classes.overlay]: overlay})}>
    	  {message && <span>{message}</span>}
    	  <CircleLoader sizeUnit="px" size={size} color={color} />
    	</div>
	  );
	}

  return (
    <div className={clsx({[classes.loader]: true, [classes.overlay]: overlay})}>
      {message && <span>{message}</span>}
      <CircleLoader sizeUnit="px" size={size} color={color} />
    </div>
  );
};

Loader.defaultProps = {
  size: 150,
  color: "#00AEEF",
  overlay: true,
};

export default Loader;

