import { createAction, mapConsts } from './utils.js';
import { api as cgApi } from 'utils/cgtelemar.js';

const Consts = {
  ...mapConsts(['ADD_SUBSYSTEM', 'SET_SUBSYSTEM', 'DROP_SUBSYSTEM']),
  ...mapConsts(['REQUEST', 'SUCCESS', 'FAILURE'], 'SUBSYSTEMS_'),
};

const addSubsystem  = createAction(Consts.ADD_SUBSYSTEM, 'subsystem');
const setSubsystem  = createAction(Consts.SET_SUBSYSTEM, 'subsystem');
const dropSubsystem = createAction(Consts.DROP_SUBSYSTEM, 'subsystem');

const askSubsystems = (token, imo) => {
  const request = createAction(Consts.SUBSYSTEMS_REQUEST, '');
  const success = createAction(Consts.SUBSYSTEMS_SUCCESS, 'data');
  const failure = createAction(Consts.SUBSYSTEMS_FAILURE, 'error');

  return dispatch => {
    dispatch(request());
    return cgApi(token, 'GET', 'ship/' + imo + '/subsystems').then(
      response => (
        dispatch(success(response.data))
      )
    ).catch(
      error => dispatch(failure({ error }))
    );
  };
}

export const Subsystems = {
  ...Consts,
  askSubsystems,
  addSubsystem,
  setSubsystem,
  dropSubsystem,
}
