import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Loader from 'components/loader';

const PasswordField = ({ label, name, handleChange, value }) => (
  <TextField
    type="password"
    name={name} label={label} placeholder={label}
    value={value} onChange={handleChange}
    margin="normal" variant="outlined"
    style={{ width: '100%' }}
  />
);

const ChangePasswordView = ({ open, handleClose, changePassword, auth }) => {
  const [state, setState] = React.useState({
    oldPassword: '',
    newPassword: '',
    renewPassword: '',
    pending: false,
    error: undefined,
  });
  
  const handleChange = e => {
    setState({
      ...state, 
      [e.target.name]: e.target.value,
    });
  }
  
  const onSave = () => {
    setState(state => ({...state, pending: true}));
    changePassword(auth, state.oldPassword, state.newPassword).then(response => {
			//console.log("RESPONSE", response);
      if (response?.data?.error || response?.error) {
        setState(state => ({...state, error: response?.data?.error || response?.error, pending: false }));
      } else {
        setState(state => ({...state, error: undefined, pending: false }));
        handleClose();
      }
    });
  }
  
  const { oldPassword, newPassword, renewPassword } = state;
  
  const newShort = newPassword.length>0 && newPassword.length < 8;
  const notEquals = (newPassword.length>0 && renewPassword.length>0) ? (newPassword !== renewPassword) : false;
  const sameOld = oldPassword === newPassword;
	const validFormat = (newPassword.length>0 && renewPassword.length>0) ? newPassword.match(/^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[\W])|(?=.*[A-Z])(?=.*\d)(?=.*[\W])|(?=.*[A-Z])(?=.*[a-z])(?=.*[\W])).{8,}$/)===null : false;

	console.log(newPassword);
	console.log(validFormat);
  
  const cantSave = newPassword.length<1 || newShort || notEquals || sameOld;

	const NewLine = ({text}) => <><label>{text}</label><br/></>
  
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Change password</DialogTitle>
      <DialogContent>
        {state.error && <Typography color="error">{Object.values(state.error).map(err => Array.isArray(err) ? err.map((e, i) => <NewLine key={i} text={e}/>) : err )}</Typography>}
        {newShort && <Typography color={newShort ? "error" : "textPrimary"}>New password must be at least 8 characters</Typography>}
        {sameOld && <Typography color={sameOld ? "error" : "textPrimary"}>Old password must not be equal to new password</Typography>}
        {notEquals && <Typography color={notEquals ? "error" : "textPrimary"}>New passwords must match</Typography>}
        {validFormat && <Typography color={validFormat ? "error" : "textPrimary"}>New password must contain at least 3 out of these 4 categories: a lower case letter, an upper case letter, a special character, a number</Typography>}
        <PasswordField name="oldPassword" label="Old password" handleChange={handleChange} value={oldPassword} />
        <PasswordField name="newPassword" label="New password" handleChange={handleChange} value={newPassword} />
        <PasswordField name="renewPassword" label="Confirm password" handleChange={handleChange} value={renewPassword} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Cancel</Button>
        <Button color="primary" variant="contained" disabled={cantSave} onClick={onSave}>Save</Button>
      </DialogActions>
      {state.pending && <Loader overlay={true} />}
    </Dialog>
  );
};

export default ChangePasswordView;
