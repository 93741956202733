import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {main: '#262730', contrastText: '#ffffff'},		//{main: '#222'},
    secondary: {light: '#5f5f6c', main: '#50505f', dark: '#373744', contrastText: '#efefef'},	//{main: '#111'},
    error: {main: '#ff4242'},
    text: {main: '#eee'},
    accent: {main: '#ed6f32', light: '#f08b5b', dark: '#a54d23'},		//'#4b90df'
		blue: {main: "#3baaff", dark: '#2770a9', light: '#6fbaf3'},
		orange: {main: '#F04C24'},
		ship: {
			red: '#df0000',
			orange: '#db8124',
			green: '#13a413',
			gray: '#666666'
		}
  },
  typography: {
    display: {
      //fontFamily: 'Digital',
      fontFamily: 'Aileron',
    },
    display2: {
      //fontFamily: 'Zen Dots',
      fontFamily: 'Aileron',
    },
    kyma: {
      header: {
        fontFamily: 'Century',
      },
      terminal: {
        fontFamily: 'Munro',
      },
    },
    hoglund: {
      log: {
        fontFamily: 'Cousine',
      },
    },
  },
  themeName: 'Midnight Old Style'
});

const shadows = (main, light = undefined) => theme.palette.augmentColor({main, light});

theme.palette.success = shadows('#0f0');
theme.palette.warning = shadows('#e6e650');
theme.palette.info = shadows('#46dcff');
theme.palette.highlight = {
  low:  shadows('rgba(0,255,0,0.3)'),
  high: shadows('rgba(255,0,0,0.3)'),
};

theme.palette.needle = shadows('#f97676', '#fea886');
theme.palette.display = {
  primary: shadows(theme.palette.primary.dark),//shadows('#2a2a2a'),//theme.palette.success,
  secondary: shadows('#222222'),//shadows('#000000'),
  error: theme.palette.error,
  warning: theme.palette.warning,
  info: theme.palette.info,
	textPrimary: '#1ef03c',
};


theme.palette.display2 = {
  primary: '#efefef',
  secondary: '#31afed',
  error: theme.palette.error,
  warning: theme.palette.warning,
  info: theme.palette.info,
};


theme.palette.kyma = {
  background: shadows('#8c99a1'),
};
theme.palette.background.default = theme.palette.primary.light;

theme.palette.hoglund = {
  log: {
    cargo_printer: '#4141ff',
    machinery_alarms: theme.palette.success.main,
    machinery_events: theme.palette.info.main,
    idle: theme.palette.success.main,
    alarm: theme.palette.error.main,
    system_alarm: theme.palette.info.main,
    event: theme.palette.warning.main,
    command: theme.palette.success.main,
    feedback: theme.palette.info.main,
  },
};

theme.palette.mednav = {
  rect: shadows('#003d79'),
  background: shadows('#fff'),
  table: shadows('#b5b5b5'),
};

theme.overrides = {
	MuiSlider: {
		root: {
			color: theme.palette.blue.dark,
		}
	},
  MuiPaper: {
    root: {
      //backgroundColor: theme.palette.secondary.light,
      //backgroundColor: theme.palette.primary.light,
      //backgroundColor: '#2a2b2f',
      backgroundColor: '#464b5f',
      color: theme.palette.secondary.contrastText,
    }
  },
	MuiIconButton: {
		root: {
			padding: '6px'
		}
	},
  MuiButton: {
    contained: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
      }
    },
		containedSecondary: {
			'&:hover': {
        color: '#efefef',//theme.palette.primary.main,
        //backgroundColor: theme.palette.primary.contrastText,
      }
		},
		textSecondary:{
			color: '#efefef'
		},
		containedPrimary: {
			backgroundColor: theme.palette.accent.main,
	      '&:hover': {
		      backgroundColor: theme.palette.accent.light,
				},
		},
  },
  MuiSpeedDial: {
    fab: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    }
  },
  MuiSwitch: {
    root: {
      '& $checked$checked': {
        //color: theme.palette.success.main,
      	color: theme.palette.accent.main,
      },
    },
    track: {
      '$checked$checked + &': {
        //backgroundColor: theme.palette.success.main,
        backgroundColor: theme.palette.accent.dark,
      }
    }
  },
	MuiMenu:{
		list: {
			padding: 0,
		}
	},
	MuiMenuItem: {
		root:{
			fontSize: 12,
			padding: '4px 10px',
			[theme.breakpoints.down('sm')]: {
				fontSize: 10,
				padding: '6px 8px',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: 9,
				padding: '4px 6px',
				minHeight: 24,
			}
		}
	},
	MTableHeader: {
		header: {
			backgroundColor: '#0003',
			fontSize: '12px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '10px',
			}
		}
	},
	MuiTableHead: {
		root: {
			backgroundColor: '#0003',
		},
	},
	MTableToolbar: {
		background: '#f00',
		root: {
			background: '#ff0',
			fontSize: 12,
			width: 180,
			[theme.breakpoints.down('md')]: {
				fontSize: '10px',
				width: 160,
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '10px',
				width: 144,
			},
			'&>div': {
				fontSize: '10px',
			}
		},
		searchField: {
			fontSize: 12,
		},
	},
	MuiTablePagination: {
		toolbar: {
			[theme.breakpoints.down('md')]: {
        fontSize: '10px',
				minHeight: 24,
      },
			[theme.breakpoints.down('sm')]: {
        fontSize: '10px',
				minHeight: 24,
      }
		},
	},
	MTablePaginationInner: {
		root: {
			[theme.breakpoints.down('md')]: {
				scale: 0.8
			},
			[theme.breakpoints.down('md')]: {
				scale: 0.8
			},
		},
	},
	MuiCheckbox: {
		root: {
			'&$checked': {
      	color: theme.palette.accent.main,
			},
		},
		colorSecondary: {
     	//color: theme.palette.accent.main,
			'&$checked': {
      	color: theme.palette.accent.main,
			},
		},
	},
	MuiPickerDTToolbar:{
		toolbar: {
			background: '#0005',
			backgroundColor: '#0005',
		}
	},
	MuiPickerDTTabs: {
		tabs: {
			background: '#0005',
			backgroundColor: '#0005',
		}
	},
	MuiPickersCalendarHeader: {
		iconButton:{
			background: '#0005',
			backgroundColor: '#0005',
			margin: '4px 16px',
		}
	},
}

export default theme;
