import React from 'react';
//import Link from '@material-ui/core/Link';

//import globals from 'utils/globals.js';

const Copyright = ({/*linkProps,*/ ...props}) => {
//      Copyright &copy; {globals.copyrightStartYear} - {new Date().getFullYear()} <Link rel="noopener noreferrer" target="_blank" href={globals.copyrightLink} {...linkProps}>{globals.copyrightName}</Link> - All rights reserved
  return (
    <span props={props.className}>
      All rights reserved &copy; Marlink {new Date().getFullYear()}
    </span>
  );
}

export default Copyright;
