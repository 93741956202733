import { createAction, mapConsts } from './utils.js';
import { api as cgApi } from 'utils/cgtelemar.js';

const Consts = {
  ...mapConsts(['ADD_CIRCLE', 'SET_CIRCLE', 'DROP_CIRCLE']),
  ...mapConsts(['REQUEST', 'SUCCESS', 'FAILURE'], 'CIRCLES_'),
};

const addCircle  = createAction(Consts.ADD_CIRCLE, 'circle');
const setCircle  = createAction(Consts.SET_CIRCLE, 'circle');
const dropCircle = createAction(Consts.DROP_CIRCLE, 'circle');

const askCircles = (token, imo) => {
  const request = createAction(Consts.CIRCLES_REQUEST, '');
  const success = createAction(Consts.CIRCLES_SUCCESS, 'data');
  const failure = createAction(Consts.CIRCLES_FAILURE, 'error');

  return dispatch => {
    dispatch(request());
    return cgApi(token, 'GET', 'ship/' + imo + '/circles').then(
      response => {
        dispatch(success(response.data));
      }
    ).catch(
      error => dispatch(failure({ error }))
    );
  };
}

export const Circles = {
  ...Consts,
  askCircles,
  addCircle,
  setCircle,
  dropCircle,
}
