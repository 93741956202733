export function createAction(type, ...argNames) {
  return function(...args) {
    const action = { type };

    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    });

    return action;
  }
};

export function mapConsts(consts, prefix = '') {
  let map = {};
  consts.forEach(value => {
    let c = prefix + value;
    map[c] = c;
  });
  return map;
};
