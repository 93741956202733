const midnightOldStyle = require('./themes/midnight-old-style.js').default;
const midnightModernStyle = require('./themes/midnight-modern-style.js').default;

const Themes = {
  'midnight-old-style': midnightOldStyle,
  'midnight-modern-style': midnightModernStyle,
  'default': 'midnight-old-style',
};

export default Themes;
