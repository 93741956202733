import { createAction, mapConsts } from './utils.js';

const Consts = {
  ...mapConsts(['ADD_SHIP_TYPE', 'CLEAR_SHIP_TYPES', 'SET_FRAME', 'SET_IMO', 'SET_SHIPS', 'SET_FLEETS', 'SET_FLEET', 'SET_FLEET_FRAME']),
};

const addShipType = createAction(Consts.ADD_SHIP_TYPE, 'shipType');
const clearShipTypes = createAction(Consts.CLEAR_SHIP_TYPES, 'shipTypes');
const setFrame = createAction(Consts.SET_FRAME, 'frame');
const setImo = createAction(Consts.SET_IMO, 'imo');
const setShips = createAction(Consts.SET_SHIPS, 'ships');

const setFleets = createAction(Consts.SET_FLEETS, 'fleets');
const setFleet = createAction(Consts.SET_FLEET, 'fleet');

const setFleetFrame = createAction(Consts.SET_FLEET_FRAME, 'fleet_frame');

export const Ship = {
  ...Consts,
  addShipType,
	clearShipTypes,
  setFrame,
  setImo,
	setShips,
	setFleets,
	setFleet,
	setFleetFrame
}
