import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { loadState, saveState } from 'utils/storage.js';
//import logger from 'middlewares/logger.js';
import reducerRegistry from 'reducers/registry.js';

const initialState = loadState() || {};

const combine = reducers => {
  const reducerNames = Object.keys(reducers);
  Object.keys(initialState).forEach(item => {
    if (reducerNames.indexOf(item) === -1) {
      reducers[item] = (state = null) => state;
    }
  });
  
  if (Object.keys(reducers).length === 0) {
    return state => state;
  }
  
  return combineReducers(reducers);
};

const combinedReducers = combine(reducerRegistry.getReducers());
const middlewares = applyMiddleware(thunk/*, logger*/);
const store = createStore(combinedReducers, initialState, middlewares);

store.subscribe(() => {
  const state = store.getState();
  
  if (state.authentication.token) {
    saveState({
      authentication: state.authentication,
			shipImo: state.shipImo,
			fleet: state.fleet,
			fleetFrame: state.fleetFrame
    });
  }
});

reducerRegistry.setChangeListener(reducers => {
  store.replaceReducer(combine(reducers));
});

//To add a reducer dinamically: reducerRegistry.register(reducerName, reducer);
reducerRegistry.fromModule('user');

export default store;
