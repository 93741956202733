import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {main: '#0f0f0f'},
    secondary: {main: '#1f1f1f'},
    error: {main: '#f00'},
    text: {main: 'white'},
  },
  typography: {
    display: {
      fontFamily: 'Digital',
    },
    kyma: {
      header: {
        fontFamily: 'Century',
      },
      terminal: {
        fontFamily: 'Munro',
      },
    },
    hoglund: {
      log: {
        fontFamily: 'Cousine',
      },
    },
  },
  themeName: 'Midnight Modern Style'
});

const shadows = (main, light = undefined) => theme.palette.augmentColor({main, light});
[theme.palette.secondary.main,theme.palette.secondary.light] = [theme.palette.secondary.light,theme.palette.secondary.main];
theme.palette.success = shadows('#0f0');
theme.palette.warning = shadows('#ff0');
theme.palette.info = shadows('#0ff');
theme.palette.highlight = {
  low:  shadows('rgba(0,255,0,0.3)'),
  high: shadows('rgba(255,0,0,0.3)'),
};

theme.palette.needle = shadows('#f97676', '#fea886');
theme.palette.display = {
  primary: theme.palette.success,
  secondary: shadows('#000000'),
  error: theme.palette.error,
  warning: theme.palette.warning,
  info: theme.palette.info,
};
theme.palette.kyma = {
  background: shadows('#8c99a1'),
};
theme.palette.background.default = theme.palette.primary.light;

theme.palette.hoglund = {
  log: {
    cargo_printer: '#4141ff',
    machinery_alarms: theme.palette.success.main,
    machinery_events: theme.palette.info.main,
    idle: theme.palette.success.main,
    alarm: theme.palette.error.main,
    system_alarm: theme.palette.info.main,
    event: theme.palette.warning.main,
    command: theme.palette.success.main,
    feedback: theme.palette.info.main,
  },
};

theme.palette.mednav = {
  rect: shadows('#003d79'),
  background: shadows('#fff'),
  table: shadows('#b5b5b5'),
};

theme.overrides = {
  MuiPaper: {
    root: {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
    }
  },
  MuiButton: {
    contained: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.contrastText,
      }
    }
  },
  MuiSpeedDial: {
    fab: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    }
  },
}

export default theme;
