import { connect } from 'react-redux';
import HeaderView from './header-view.js'
import { User, Ship } from 'actions';

function getUserDetails(dispatch, auth, imo) {
  if (!auth || !auth.token) return;
  dispatch(User.askUser(auth.token, imo));
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.authentication,
  user: state.user,
	ships: state.ships,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  askUser: (auth, imo) => { getUserDetails(dispatch, auth, imo) },
	logout: () => dispatch(User.logout()),
});

const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderView);

export default Header;
