import { createAction, mapConsts } from './utils.js';
import { login as cgLogin, api as cgApi,
	send_reset_password_link as cgSendResetPasswordLink,
	send_new_password as cgSendNewPassword,
	redirect as redirectTo, download as apiDL } from 'utils/cgtelemar.js';

const Consts = {
  ...mapConsts(['REQUEST', 'SUCCESS', 'FAILURE'], 'LOGIN_'),
  ...mapConsts(['REQUEST', 'SUCCESS', 'FAILURE'], 'API_'),
  ...mapConsts(['REQUEST', 'SUCCESS', 'FAILURE'], 'PAYLOAD_'),
  ...mapConsts(['REQUEST', 'SUCCESS', 'FAILURE'], 'SET_PAYLOAD_'),
  ...mapConsts(['REQUEST', 'SUCCESS', 'FAILURE'], 'ROUTEPATH_'),
  ...mapConsts(['REQUEST', 'SUCCESS', 'FAILURE'], 'USER_'),
  ...mapConsts(['LOGOUT', 'RESET_PAYLOAD', 'RESET_API']),
}

const redirect = (token, path) => redirectTo(token, path)

const autologin = (token) => {
  const success = createAction(Consts.LOGIN_SUCCESS, 'auth');
  
  return dispatch => {
    dispatch(success({ token }));
  }
}

const login = (username, password) => {
  const request = createAction(Consts.LOGIN_REQUEST, 'user');
  const success = createAction(Consts.LOGIN_SUCCESS, 'auth');
  const failure = createAction(Consts.LOGIN_FAILURE, 'error');
  
  return dispatch => {
    dispatch(request({ username }));
    return cgLogin(username, password).then(
      response => {
        if (response.authenticated) {
          dispatch(success(response.data));
        } else {
          dispatch(failure(response.error));
        }
      }
    ).catch(
      error => dispatch(failure({ error }))
    );
  };
}

const sendResetPasswordLink = (username) => {
	const request = createAction(Consts.API_REQUEST, 'data');
  const success = createAction(Consts.API_SUCCESS, 'data');
  const failure = createAction(Consts.API_FAILURE, 'data');
 
  return dispatch => {
    dispatch(request({ username }));
    return cgSendResetPasswordLink(username).then(
      response => {
        if (response.done) {
					//console.log(response.data)
          dispatch(success({username, response: response.data}));
					//return response.data;
					return {message: 'if a user with that email address exists, a password reset link has been sent'};
        } else {
          dispatch(failure({username, response: response.error}));
					return response.error;
        }
      }
    ).catch(
      error => {
				dispatch(failure({username, response: error }))
				return error;
			}
    );
  };
	
}

const sendNewPassword = (username, password, token) => {
  const request = createAction(Consts.API_REQUEST, 'data');
  const success = createAction(Consts.API_SUCCESS, 'data');
  const failure = createAction(Consts.API_FAILURE, 'data');

	//console.log(username, password)
	
  return dispatch => {
    dispatch(request({ username }));
    return cgSendNewPassword(username, password, token).then(
      response => {
				//console.log(response)
        if (response.done) {
				  dispatch(success({username, response: response.data}));
					login(username, password);
					return response.data;
        } else {
          dispatch(failure({username, response: response.error}));
          return response.error;
        }
      }
    ).catch(
      error => {
				dispatch(failure({username, response: error }))
        return error;
      }     
    );
  };

}


const download = (token, path, data=null) => {
  const request = createAction(Consts.API_REQUEST, 'data');
  const success = createAction(Consts.API_SUCCESS, 'data');
  const failure = createAction(Consts.API_FAILURE, 'data');

  return dispatch => {
    return apiDL(token, path, data)
  };
}

const api = (token, path, method = 'GET', data = null, key = 'generic') => {
  const request = createAction(Consts.API_REQUEST, 'data');
  const success = createAction(Consts.API_SUCCESS, 'data');
  const failure = createAction(Consts.API_FAILURE, 'data');
  
  return dispatch => {
    dispatch(request({ key }));
    return cgApi(token, method, path, data).then(
      response => {
        dispatch(success({ key, response: response.data }));
        return response.data;
      }
    ).catch(
      error => {
        dispatch(failure({ key, response: error }))
        return error;
      }
    );
  };
}

const volatileApi = (token, path, method = 'GET', data = null) => {
  return cgApi(token, method, path, data);
//  return cgApi(token, method, path, data).then(
//    response => response.data
//  ).catch(
//    error => error
//  );
}

const askPayload = (token, imo) => {
  const request = createAction(Consts.PAYLOAD_REQUEST, '');
  const success = createAction(Consts.PAYLOAD_SUCCESS, 'data');
  const failure = createAction(Consts.PAYLOAD_FAILURE, 'error');
  
  return dispatch => {
    dispatch(request());
    return cgApi(token, 'GET', 'ship/' + imo + '/payload').then(
      response => {
        dispatch(success(response.data));
      }
    ).catch(
      error => dispatch(failure({ error }))
    );
  };
}

const setPayload = (token, imo, payload) => {
  const request = createAction(Consts.SET_PAYLOAD_REQUEST, 'data');
  const success = createAction(Consts.SET_PAYLOAD_SUCCESS, 'data');
  const failure = createAction(Consts.SET_PAYLOAD_FAILURE, 'error');

  return dispatch => {
    dispatch(request(payload));
    return cgApi(token, 'PUT', 'ship/' + imo + '/payload', JSON.stringify(payload)).then(
      response => {
        dispatch(success());
      }
    ).catch(
      error => dispatch(failure({ error }))
    );
  };
}

const askRoutePath = (token, imo, date, duration, step) => {
  const request = createAction(Consts.ROUTEPATH_REQUEST, '');
  const success = createAction(Consts.ROUTEPATH_SUCCESS, 'data');
  const failure = createAction(Consts.ROUTEPATH_FAILURE, 'error');

  return dispatch => {
    dispatch(request());
    return cgApi(token, 'GET', 'ship/' + imo + '/route/' + date + '/' + duration + '/' + step).then(
      response => {
        dispatch(success(response.data));
      }
    ).catch(
      error => dispatch(failure({ error }))
    );
  };
}

const askUser = (token, imo) => {
  const request = createAction(Consts.USER_REQUEST, '');
  const success = createAction(Consts.USER_SUCCESS, 'data');
  const failure = createAction(Consts.USER_FAILURE, 'error');
  
  return dispatch => {
    dispatch(request());
    return cgApi(token, 'GET', 'user' + (imo ? `/${imo}` : '')).then(
      response => {
        dispatch(success(response.data));
      }
    ).catch(
      error => dispatch(failure({ error }))
    );
  };
}

const logout = () => {
  const logout = {type: Consts.LOGOUT};
  const resetPayload = {type: Consts.RESET_PAYLOAD};
  const resetApi = {type: Consts.RESET_API};
  
  return dispatch => {
    dispatch(logout);
    dispatch(resetPayload);
    dispatch(resetApi);
  };
}

const resetApi = () => {
  const resetApi = {type: Consts.RESET_API};
  return dispatch => dispatch(resetApi);
}

export const User = {
  ...Consts,
	redirect,
  login,
  logout,
  api,
  volatileApi,
  askPayload,
  setPayload,
  askRoutePath,
  autologin,
  resetApi,
  askUser,
  sendResetPasswordLink,
	sendNewPassword,
	download,
}
