import { createReducer } from './utils.js';
import { Ship } from 'actions';

const reducers = {
  frame: createReducer({}, {
    [Ship.SET_FRAME]: (state, action) => action.frame
  }),
  
  shipTypes: createReducer({}, {
    [Ship.ADD_SHIP_TYPE]: (state, action) => ({...state, [action.shipType.key]: action.shipType.value}),
    [Ship.CLEAR_SHIP_TYPES]: (state, action) => (null)
  }),
  
  shipImo: createReducer(null, {
    [Ship.SET_IMO]: (state, action) => action.imo
  }),

  ships: createReducer(null, {
    [Ship.SET_SHIPS]: (state, action) => action.ships
  }),

  fleets: createReducer(null, {
    [Ship.SET_FLEETS]: (state, action) => action.fleets
  }),
  fleet: createReducer(null, {
    [Ship.SET_FLEET]: (state, action) => action.fleet
  }),

	fleetFrame: createReducer(null, {
    [Ship.SET_FLEET_FRAME]: (state, action) => action.fleet_frame
	}),
};

export default reducers;
