import { connect } from 'react-redux';
import { User } from 'actions';
import PasswordChangeView from './password-change-view.js';
import reducerRegistry from 'reducers/registry.js';

reducerRegistry.fromModule('ship');

function changePassword(auth, oldPassword, newPassword) {
  if (!auth || !auth.token) return;
  
  const data = JSON.stringify({
    old: oldPassword,
    'password': newPassword,
    'password_confirmation': newPassword,
  });

  return User.volatileApi(auth.token, 'passwd', 'PUT', data);
}

const mapStateToProps = (state, ownProps) => ({
  auth: state.authentication,
})

const mapDispatchToProps = dispatch => ({
  changePassword,
})

const PasswordChange = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordChangeView);

export default PasswordChange;
