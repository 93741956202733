import React from 'react';
import { render, createRoot } from 'react-dom';
import { Provider as StateProvider } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';

import App from './components/app';
import ThemeProvider from 'components/theme-provider';

import store from 'store.js';
import * as serviceWorker from './serviceWorker.js';

import 'default-passive-events';
import 'typeface-roboto';
import './index.css';

render(
  <StateProvider store={store}>
    <ThemeProvider>
      <CssBaseline />
      <App state={store.getState()}/>
    </ThemeProvider>
  </StateProvider>,
//  document.body
  document.getElementById('root')
);

/*
const root = createRoot(document.getElementById('root'));
root.render(
  <StateProvider store={store}>
    <ThemeProvider>
      <CssBaseline />
      <App state={store.getState()}/>
    </ThemeProvider>
  </StateProvider>
);
*/



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
