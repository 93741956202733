import _ from 'lodash';
import { createReducer } from './utils.js';
import { Circles } from 'actions';

const initialCirclesState = {
  pending: false,
  error: undefined,
  circles: undefined,
}

const circlesRequest = (state, action) => {
  return {
    ...state,
    pending: true,
  }
}

const circlesSuccess = (state, action) => {
  return {
    ...state,
    circles: action.data.circles,
    pending: false,
    error: undefined,
  }
}

const circlesFailure = (state, action) => {
  return {
    ...state,
    circles: undefined,
    pending: false,
    error: action.error,
  }
}

const reducers = {
  circles: createReducer(initialCirclesState, {
    [Circles.ADD_CIRCLE]:  (state, action) => { let id = _.uniqueId(); return {...state, [id]: action.circle}; },
    [Circles.SET_CIRCLE]:  (state, action) => ({...state, [action.circle.id]: action.circle }),
    [Circles.DROP_CIRCLE]: (state, action) => { const { [action.circle.id]: dropped, ...newState} = state; return newState; },
    
    [Circles.CIRCLES_REQUEST]: circlesRequest,
    [Circles.CIRCLES_SUCCESS]: circlesSuccess,
    [Circles.CIRCLES_FAILURE]: circlesFailure,
  }),
};

export default reducers;
