import _ from 'lodash';
import { createReducer } from './utils.js';
import { Subsystems } from 'actions';

const initialSubsystemsState = {
  pending: false,
  error: undefined,
  subsystems: undefined,
}

const subsystemsRequest = (state, action) => {
  return {
    ...state,
    pending: true,
  }
}

const subsystemsSuccess = (state, action) => {
  return {
    ...state,
    subsystems: action.data.subsystems,
    pending: false,
    error: undefined,
  }
}

const subsystemsFailure = (state, action) => {
  return {
    ...state,
    subsystems: undefined,
    pending: false,
    error: action.error,
  }
}

const reducers = {
  subsystems: createReducer(initialSubsystemsState, {
    [Subsystems.ADD_SUBSYSTEM]:  (state, action) => { let id = _.uniqueId(); return {...state, [id]: action.subsystem}; },
    [Subsystems.SET_SUBSYSTEM]:  (state, action) => ({...state, [action.subsystem.id]: action.subsystem }),
    [Subsystems.DROP_SUBSYSTEM]: (state, action) => { const { [action.subsystem.id]: dropped, ...newState} = state; return newState; },
    
    [Subsystems.SUBSYSTEMS_REQUEST]: subsystemsRequest,
    [Subsystems.SUBSYSTEMS_SUCCESS]: subsystemsSuccess,
    [Subsystems.SUBSYSTEMS_FAILURE]: subsystemsFailure,
  }),
};

export default reducers;
