import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { Grid, useTheme, makeStyles, AppBar, Button, ClickAwayListener, Drawer, 
  Grow, Hidden, IconButton, List, ListItem, ListItemIcon, ListItemText, Tab, Tabs, MenuList, 
  MenuItem, Paper, Popper, Typography, Toolbar } from '@material-ui/core';

import ChangePasswordIcon from '@material-ui/icons/Lock';
import CirclesIcon from '@material-ui/icons/ErrorOutline';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/MoreVert';
import PlayerIcon from '@material-ui/icons/History';
import PublicIcon from '@material-ui/icons/Public';
import RealtimeIcon from '@material-ui/icons/LiveTv';
import RouteIcon from '@material-ui/icons/Timeline';
import GrapherIcon from '@material-ui/icons/Equalizer';
import UserIcon from '@material-ui/icons/AccountCircle';
import SubsystemsIcon from '@material-ui/icons/Storage';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import ServiceStatusIcon from '@material-ui/icons/SettingsInputAntenna';
//import UsersIcon from '@material-ui/icons/Group';
import VersionIcon from '@material-ui/icons/SystemUpdateAlt';
import CollectionConfigIcon from '@material-ui/icons/SettingsApplications';
import WarningIcon from '@material-ui/icons/Warning';
import DataQualityIcon from '@material-ui/icons/PlaylistAddCheck';
import GrafanaIcon from '@material-ui/icons/Dashboard';
//import VesselsIcon from '@material-ui/icons/DirectionsBoat';

import { AlertsIcon, AnalyticsIcon, ReportsIcon, OperationsIcon, SystemIcon, DataIcon, CollectionsIcon,
	EdgeStatusIcon, VesselsIcon, UsersIcon, OverviewIcon, FleetMonitorIcon,
	QualityIcon, MappingIcon, TimeMachineIcon, GeofenceIcon} from 'utils/icons';

import DataUsageIcon from '@material-ui/icons/DataUsage';
import PageviewIcon from '@material-ui/icons/Pageview';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import AccountTreeIcon from '@material-ui/icons/AccountTree';

import ChangePassword from 'components/password-change';

//import Logo from 'assets/images/logo.svg.js';
//import Logo from 'assets/images/logo-reverse.png';
import Logo from 'assets/images/logo-reverse-156.png';

import ShipsSelector from 'utils/ships-selector.js';
import ShipsSearchSelector from 'utils/ships-search-selector.js';

const useStyles = makeStyles(theme => ({
  appBar: {
		backgroundColor: '#2e303f',
  },
	appBarWithMargin: {
		backgroundColor: '#2e303f',
		marginBottom: '56px',
	},
  sideBar: {
    zIndex: '10000 !important',
  },
	menu: {
		zIndex: 9,
    marginTop: 8,
	},
	centerDiv: {
    display: 'inline-flex',
    alignItems: 'center',
	},
  logo: {
		verticalAlign: 'middle',
    height: 'auto',
    padding: theme.spacing(1),
  	width: '136px',
  	[theme.breakpoints.down('md')]: {
    	width: '108px',
  	},
  	[theme.breakpoints.down('sm')]: {
    	width: '88px',
  	},
	},
	avatar: {
		width: '24px',
		height: '24px',
		borderRadius: '50%',
    margin: '-2px 6px',
		marginLeft: 10,
		alignSelf: 'center',
  	[theme.breakpoints.down('md')]: {
			width: 18,
			height: 18,
		}
	},
	customer_logo: {
		maxHeight: '60px',
		width: '48px',
    maxWidth: '60px',
		padding: '4px',
  	[theme.breakpoints.down('md')]: {
			maxWidth: '40px',
		}
	},
	firstToolbar: {
		//marginLeft: '99px',
		paddingRight: '0',
		maxHeight: '56px',
    minHeight: '56px',
  	[theme.breakpoints.down('md')]: {
			maxHeight: '48px',
    	minHeight: '48px',
  	},
		'&>div': {
			maxHeight: '56px',
	    minHeight: '56px',
  		[theme.breakpoints.down('md')]: {
				maxHeight: '48px',
    		minHeight: '48px',
  		},
		}
	},
	shipsSelector: {
	  position: 'absolute',
  	top: 56,
		left: 8,
  	[theme.breakpoints.down('md')]: {
			top: 48,
		},
  	[theme.breakpoints.down('sm')]: {
			top: 48,
		}
	},
	profile_column: {
		height: '100%',
		width: '100%',
		display: 'inline-flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'row',
		scale: 0.9,
		zIndex: 999,
		position: 'relative',
		justifyContent: 'flex-end',
	},
  title: {
    flexGrow: 1,
    textAlign: 'center',
    position: 'relative',
    fontSize: '1.2rem',
		'&>label':{
    	marginLeft: theme.spacing(1),
		}
  },
  icon: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'top',
  },
  blinky: {
    animation: '$blinker 2s linear infinite alternate',
  },
  '@keyframes blinker': {
    '0%'  : { opacity: 1.0 },
    '75%' : { opacity: 0.9 },
    '100%': { opacity: 0.0 },
  },
  fader: {
    animation: '$fader 4s linear infinite alternate',
  },
  faderReversed: {
    opacity: 0,
    position: 'absolute',
    marginLeft: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    animation: '$faderReversed 4s linear infinite alternate',
  },
  '@keyframes fader': {
    '0%'  : { opacity: 1.0 },
    '50%' : { opacity: 1.0 },
    '75%' : { opacity: 0.0 },
    '100%': { opacity: 0.0 },
  },
  '@keyframes faderReversed': {
    '0%'  : { opacity: 0.0 },
    '50%' : { opacity: 0.0 },
    '75%' : { opacity: 1.0 },
    '100%': { opacity: 1.0 },
  },
  compact: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1200,
  },
  profileMenuBottom: {
    cursor: 'pointer',
		/*display: 'contents',
		fontSize: '0.88em',*/
		display: 'flex',
		fontSize: '1.1vw',
		[theme.breakpoints.up('lg')]: {
			fontSize: 14,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 10,
		}
  },
}));

const useLinkStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
  },
  button: {
    marginRight: theme.spacing(3),
  },
	tab: {
		fontSize: '0.8em',
		fontWeight: 400,
		opacity: 1,
		maxHeight: 56,
		minHeight: 56,
		maxWidth: 128,
		minWidth: 128,
		[theme.breakpoints.down('md')]:{
			maxWidth: 96,
			minWidth: 96,
			maxHeight: 48,
			minHeight: 48,
			fontSize: '0.6rem',
		},
		[theme.breakpoints.down('sm')]:{
			maxWidth: 70,
			minWidth: 70,
			padding: 0,
		},
		'&:hover': {
			backgroundColor: '#868686 !important',
		},
		'&>span': {
			scale: '0.9',
			[theme.breakpoints.down('md')]:{
				scale: '0.8',
			},
			[theme.breakpoints.down('sm')]:{
				scale: '0.7',
			},
		},
	},
}));

/*
const links = [
  {path: "/overview", name: "Overview", Icon: PublicIcon, labelWithIcon: true, filterImo: true},
  {path: "/connectivity", name: "Service Status", Icon: ServiceStatusIcon, labelWithIcon: true, filterImo: true},
  {path: "/vessels", name: "Vessels", Icon: VesselsIcon, labelWithIcon: true, filterImo: true},
  {path: "/users", name: "Users", Icon: UsersIcon, labelWithIcon: true, filterImo: true, service: 'users'},
  {path: "/version", name: "Version", Icon: VersionIcon, labelWithIcon: true, blinky: false, filterImo: true, service: 'versions'},
  {path: "/collections", name: "Collections", Icon: CollectionConfigIcon, labelWithIcon: false, blinky: false, filterImo: true, service: 'coll_conf'},
  {path: "/alerts", name: "Live Alerts", Icon: WarningIcon, labelWithIcon: true, blinky: false, refresh: true, filterImo: true},	//service: 'alerts'},
  {path: "/alerts-history", name: "History Alerts", Icon: PlayerIcon, labelWithIcon: true, blinky: false, refresh: true, hidden: true},
  {path: "/:imo/realtime", name: "Live", Icon: RealtimeIcon, labelWithIcon: true, blinky: false, fader: true},
  {path: "/:imo/edit", name: "Edit", Icon: EditIcon, labelWithIcon: true, blinky: false, fader: true},
  {path: "/:imo/dataquality", name: "Data Quality", Icon: DataQualityIcon, labelWithIcon: true, blinky: false, fader: true},
  {path: "/:imo/grafana", name: "Grafana", Icon: GrafanaIcon, labelWithIcon: true, blinky: false, fader: true},
  {path: "/:imo/circles", name: "Geofence Zones", Icon: CirclesIcon, labelWithIcon: true, blinky: false, fader: true, service: 'zones'},
  {path: "/:imo/timemachine", name: "Time Machine", Icon: PlayerIcon, refresh: true, labelWithIcon: true, blinky: false, fader: true, service: 'time-machine'},
  {path: "/:imo/voyages", name: "Voyages", Icon: RouteIcon, labelWithIcon: true, blinky: false, fader: true, service: 'traffickings'},
  {path: "/:imo/grapher", name: "Grapher", Icon: GrapherIcon, refresh: true, labelWithIcon: true, blinky: false, fader: true, service: 'graphs'},
  {path: "/:imo/subsystems", name: "Subsystems", Icon: SubsystemsIcon, refresh: true, labelWithIcon: true, blinky: false, fader: true, service: 'subsystems'},
  {path: "/:imo/collections", name: "Collections", Icon: CollectionConfigIcon, labelWithIcon: false, blinky: false, service: 'coll_conf'},
  {path: "/:imo/alerts", name: "Live Alerts", Icon: WarningIcon, labelWithIcon: true, blinky: false, refresh: true, fader: true},
  {path: "/:imo/alerts-history/:type?/:id?", name: "History Alerts", Icon: PlayerIcon, labelWithIcon: true, blinky: false, refresh: true, hidden: true},
];
*/

const submenu_links = [
  {submenu: "", path: "/alerts", name: "Live Alerts", Icon: AlertsIcon, labelWithIcon: true, blinky: false, refresh: true, filterImo: false, service: 'portal_alerts_disconnection_alerts,portal_alerts_collecion_alerts'},
  {submenu: "SYSTEM", path: "/iot/overview", name: "Overview", Icon: OverviewIcon, labelWithIcon: true, filterImo: false, service: 'portal_system_overview-read'},
  {submenu: "SYSTEM", path: "/iot/fleetmonitor", name: "FLEET", Icon: FleetMonitorIcon, labelWithIcon: true, filterImo: false, service: 'portal_system_fleet_monitor-read'},
  {submenu: "SYSTEM", path: "/iot/vessels", name: "VESSELS", Icon: VesselsIcon, labelWithIcon: true, filterImo: false, service: 'portal_system_vessel-read'},
  {submenu: "SYSTEM", path: "/iot/collections", name: "COLLECTIONS", Icon: CollectionsIcon, labelWithIcon: false, blinky: false, filterImo: false, service: 'portal_system_collections-read'},
  {submenu: "SYSTEM", path: "/iot/edgestatus", name: "EDGE STATUS", Icon: EdgeStatusIcon, labelWithIcon: true, filterImo: false, service: 'portal_system_edge_status-read'},
  {submenu: "", path: "/users", name: "Users", Icon: UsersIcon, labelWithIcon: true, filterImo: false, service: 'portal_users-read'},
  {submenu: "DATA", path: "/data/overview", name: "Overview", Icon: OverviewIcon, labelWithIcon: true, filterImo: false, service: 'portal_data_overview-read'},
  {submenu: "DATA", path: "/data/fleetmonitor", name: "FLEET", Icon: FleetMonitorIcon, labelWithIcon: true, filterImo: false, fleetSelector: true, service: 'portal_data_fleet_monitor-read'},
  {submenu: "DATA", path: "/data/vessels", name: "VESSELS", Icon: VesselsIcon, labelWithIcon: true, filterImo: false, service: 'portal_data_vessel-read'},
  {submenu: "DATA", path: "/data/quality", name: "Quality", Icon: QualityIcon, labelWithIcon: true, blinky: false, fader: true, shipsSelector: false, service: 'portal_data_quality-read'},
  {submenu: "DATA", path: "/data/mapping", name: "Mapping", Icon: MappingIcon, labelWithIcon: true, blinky: false, fader: true, shipsSelector: false, service: 'portal_data_mapping-read'},

	{submenu: "OPERATIONS", path: "/operations/live", name: "Live", Icon: OverviewIcon, labelWithIcon: true, filterImo: false, service: 'portal_operations_live-read'},
	{submenu: "OPERATIONS", path: "/operations/vessels", name: "Vessels", Icon: VesselsIcon, labelWithIcon: true, blinky: false, fader: true, shipsSelector: true, service: 'portal_operations_vessel-read'},
	{submenu: "OPERATIONS", path: "/operations/timemachine", name: "Time Machine", Icon: TimeMachineIcon, refresh: true, labelWithIcon: true, blinky: false, fader: true, service: 'portal_operations_time_machine-read', shipsSelector: true},
	{submenu: "OPERATIONS", path: "/operations/circles", name: "Geofence", Icon: GeofenceIcon, labelWithIcon: true, blinky: false, fader: true, service: 'portal_operations_geofence-read', shipsSelector: true},
//	{submenu: "OPERATIONS", path: "/operations/dashboard", name: "Dashboard", Icon: CirclesIcon, labelWithIcon: true, blinky: false, fader: true, service: 'zones'},

	{submenu: "ANALYTICS", path: "/analytics/reports", name: "Reports", Icon: ReportsIcon, labelWithIcon: true, blinky: false, fader: true, service: 'portal_operations_analytics-read'},
];

const main_menu_links = [
	{ name: "ALERTS", Icon: AlertsIcon, path: "/alerts", service: 'portal_alerts_disconnection_alerts-read,portal_alerts_collecion_alerts-read'},
	{ name: "SYSTEM", Icon: SystemIcon, path: "/iot/overview", service: 'portal_system_overview-read,portal_system_fleet_monitor-read,portal_system_vessel-read,portal_system_collections-read,portal_system_edge_status-read'},
	{ name: "DATA", Icon: DataIcon, path: "/data/overview", service: 'portal_data_overview-read,portal_data_fleet_monitor-read,portal_data_vessel-read,portal_data_quality-read,portal_data_mapping-read'},
	{ name: "OPERATIONS", Icon: OperationsIcon, path: "/operations/live", service: 'portal_operations_live-read,portal_operations_vessel-read,portal_operations_time_machine-read,portal_operations_geofence-read'},
	{ name: "ANALYTICS", Icon: AnalyticsIcon, path: "/analytics/reports", service: 'portal_operations_analytics-read'},
	{ name: "USERS", Icon: UsersIcon, path: "/users", service: 'portal_users-read'},
];

const currentLink = (props) => submenu_links.filter(el => props.match.path === el.path)[0];

const NamedLink2 = ({ active, hidden, path, name, filterImo, Icon, imo, id, refresh, handleClick, ...props }) => {
  const theme = useTheme();
  const classes = useLinkStyles();

  let color = "transparent";
  if(props.match.path === path || active) {
    color = theme.palette.orange.main;
  }

	const onClick = (open) => {
		handleClick(name, open)
	}	


  return (<>{
		hidden ? <Tab className={classes.tab} style={{visibility: 'hidden'}}/> : 
    <Tab
			style={{background:color}}
			className={classes.tab}
			onClick={() => onClick(true, path)}
      label={name}
      icon={<Icon/>} 
      component={Link} to={path} 
      />
  }</>);

/*
  return (path==undefined ?
		<Tab
    	style={{background:color}}
    	className={classes.tab}
    	label={name}
			onClick={() => onClick(true)}
    	icon={<Icon/>}
			component={Link} to="#"
			/>
    : <Tab
      style={{background:color}}
      className={classes.tab}
			onClick={() => onClick(true, path)}
      label={name}
      icon={<Icon/>}
      component={Link} to={path}
      />
		)
*/
}

const NamedLink = ({ submenu, hidden, path, name, filterImo, Icon, imo, id, refresh, ...props }) => {
	const theme = useTheme();
  const classes = useLinkStyles();
 
  if (path.includes(":imo") && !imo) {
    return null;
  }
  
  if (imo && filterImo) {
    return null;
  }
	
	if(!id && path.includes(":id?")){
		path = path.replace(":id?", "");
	}

  const to = path.replace(":imo", imo).replace(":id?", id);
  
	let color = "transparent";
  if(props.match.path === path) {
    color = theme.palette.orange.main;
    //color = theme.palette.blue.main;
  }

  return (<>{
		hidden ? <Tab className={classes.tab} style={{visibility: 'hidden'}}/> : 
    <Tab
			style={{background:color}}
			className={classes.tab}
      label={name}
      icon={<Icon/>} 
      component={Link} to={to} 
      />
  }</>);
}


const HeaderView = (props) => {
  const classes = useStyles();
  const page = currentLink(props);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = React.useState(false);
  const [compact, setCompact] = React.useState(false);
  const [changePasswordVisibility, setChangePasswordVisibility] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(false);
	const [openSubMenu, setOpenSubMenu] = React.useState(false)
	const [submenu, setSubMenu] = React.useState([])
	const [isInitialRender, setIsInitialRender] = React.useState(true)
	const [selectedShip, setSelectedShip] = React.useState(props.imo);

	const [showShipsSelector, setShowShipsSelector] = React.useState(false)
	const [imoChanged, setImoChanged] = React.useState(false)
	const [customerLogo, setCustomerLogo] = React.useState(false)
  
  const anchorRef = React.useRef(null);
	const {user} = props
  
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleCompact = () => setCompact(!compact);
  const onProfileMenuClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
		setProfileMenuOpen(false);
	}
  const toggleProfileMenu = e => {
		setProfileMenuOpen(!profileMenuOpen);
	}
  const showChangePassword = () => setChangePasswordVisibility(true);
  const closeChangePassword = () => setChangePasswordVisibility(false);

  //const [filteredLinks, setFilteredLinks] = React.useState()
  //const [filteredMainLinks, setFilteredMainLinks] = React.useState()

	React.useEffect(() => {
 		if (!user || (!user.user && !user.pending)) {
 	  	props.askUser(props.auth, props.imo);
	  }
	}, [])

	React.useEffect(() => {
		if(user.user){
			setCustomerLogo(logo => user.user.customer_logo)
		}else{
			if(!user.pending && user.error){
				props.logout();
			}
		}
	}, [user])

	const checkPermissions = (perms) => {
		perms = perms.split(",");
		let out=false;
		perms.forEach(perm => (out += services.includes(perm)))
		return out;
	}
  
  const services = props.user?.user?.services.permissions || {};
  //const filteredLinks = links.filter(link => link.hidden ? false : link.service ? services[link.service] : true);
  //const filteredLinks = submenu_links.filter(link => link.service ? services.includes(link.service) : false);
  //const filteredMainLinks = main_menu_links.filter(link => link.service ? services.includes(link.service) : true);

  const filteredLinks = submenu_links.filter(link => link.service ? checkPermissions(link.service) : false);
  const filteredMainLinks = main_menu_links.filter(link => link.service ? checkPermissions(link.service) : true);

	const Menu = () => {
		let menu = filteredMainLinks;

		const length = menu.length;
		if(length < submenu.length){
			const max = (submenu.length - length);
			for(let i=0; i<max; i++){
				menu.push({path:'', hidden: true})
			}
		}

		return menu.map((link, index) => {
			let name = link.name
			return <NamedLink2 {...link} {...props} key={index} handleClick={openMenu} active={name==activeTab} />
		})
	}

	const openMenu = (sm, open=true) => {
		if(open){
			let menu = filteredLinks.filter((link, index) => link.submenu == sm)
			//console.log(menu)
			setActiveTab(activeTab => sm)
			setSubMenu(_menu => menu)
			if(menu.length > 0){
				setOpenSubMenu(true)
			}else{
				setOpenSubMenu(false)
			}
		}else{
			setOpenSubMenu(false)
		}
	}

	const SubMenu = () => {
		const length = submenu.length;
		if(filteredMainLinks.length > length){
			const max = (filteredMainLinks.length-length);
			for(let i=0; i<max; i++){
				submenu.push({name: '', path:'', hidden: true})
			}
		}
		return submenu.map((link, index) => <NamedLink {...link} {...props} key={index} />)
	}

 	React.useEffect(() => {
		if(Object.keys(services).length>0 && isInitialRender){

      //setFilteredLinks(submenu_links.filter(link => link.service ? services.includes(link.service) : false))
      //setFilteredMainLinks(main_menu_links.filter(link => link.service ? services.includes(link.service) : true))

			setIsInitialRender(false)
			let item = filteredLinks.find(link => props.match.path === link.path )
			if(item){
				setActiveTab(item.name)
				if(item.submenu!=''){
					openMenu(item.submenu)
				}
				setShowShipsSelector(item.shipsSelector ? true : false)
			}
		}
	}, [services, isInitialRender])

	React.useEffect(() => {
		if(imoChanged){
			setImoChanged(false)
			window.location.reload()
		}
	}, [imoChanged])

/*
  if (!props.user || (!props.user.user && !props.user.pending)) {
    props.askUser(props.auth, props.imo);
		return 
  }
*/

  const handleChangeShip = (imo) => {
    if(imo){
			props.setImo(imo)
			setSelectedShip(imo)
    }
  }

	const onShipChanged = (ev, value=null) => {
		console.log("ship changed", ev.target.value)
		console.log("ship changed", value)
		//let changed = ev.target.value != props.imo
		//props.setImo(ev.target.value)
		//setImoChanged(changed)
	
		console.log(ev)
    console.log(value)
    if(!ev) return
    ev.preventDefault();
    ev.stopPropagation();
    if(value){
      let val=null    //ev.target.value
      if( (typeof value)==='string' && value.includes('/')){
        val = value.split('/').at(-1).trim()
      }else{
        val = value.imo
      }
      console.log(val)
      if(val){
				let changed = val != props.imo
				props.setImo(val)
				setImoChanged(changed)
      }
    }
	}

	return (
    <>
      {!compact && <AppBar position="static" className={showShipsSelector ? classes.appBarWithMargin : classes.appBar}>
        <Toolbar className={classes.firstToolbar}>
					<Grid container>
						<Grid item xs={2} className={classes.centerDiv}>
	          	<a href="/" title="Overview"><img src={Logo} alt="Bridge Link Dashboard" className={classes.logo} /></a>
						</Grid>
						<Grid item xs={8}>
						<Tabs variant="scrollable" scrollButtons="auto" aria-label="tab list menu"
              style={{margin:"0 auto", width: 'fit-content'}}
							indicatorColor="primary"
							TabIndicatorProps={{}}
							value={false}
						>
							<Menu />
          	</Tabs>
						</Grid>
						<Grid item xs={2}>
						<div className={classes.profile_column}>
							{props.user?.user?.customer_logo && <img src={props.user.user.customer_logo} alt="Customer Logo" className={classes.customer_logo}  onError={(e) => (e.target.style.display='none')} />}
  	        	<Typography onClick={toggleProfileMenu} variant="body2" ref={anchorRef}>
								<div className={classes.profileMenuBottom}>
    	        	{/*<IconButton><UserIcon /></IconButton>*/}
								{props.user?.user?.avatar
									? (<Hidden smDown><img alt="" src={props.user.user.avatar} className={classes.avatar}/></Hidden>)
									: (<Hidden smDown><IconButton><UserIcon /></IconButton></Hidden>)
								}
								{props.user?.user?.name || ''}
           			{/*<Hidden mdDown>{props.user?.user?.name || ''}</Hidden>*/}
								</div>
          		</Typography>         
          			<IconButton onClick={toggleCompact}><ExpandLessIcon /></IconButton>
						</div>
						</Grid>
					</Grid>
        </Toolbar>

          <Popper size="small" open={profileMenuOpen} anchorEl={anchorRef.current} transition disablePortal className={classes.menu} placement='bottom-start'>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'top bottom' }}>
                <Paper>
                  <ClickAwayListener onClickAway={onProfileMenuClose}>
                    <MenuList autoFocusItem={false}>
                      <MenuItem dense onClick={showChangePassword}><ChangePasswordIcon /> Change password</MenuItem>
                      <MenuItem dense onClick={() => window.location.href = "/logout"}><LogoutIcon /> Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper> 

				{openSubMenu &&
        (<Toolbar className={classes.firstToolbar}>
						<Grid container>
							<Grid item xs={2}></Grid>
							<Grid item xs={8}>
            	<Tabs variant="scrollable" scrollButtons="auto" aria-label="tab list menu"
              	style={{margin:"0 auto", width: 'fit-content'}}
            	  indicatorColor="primary"
          	    TabIndicatorProps={{}}
        	      value={false}
      	      >
    	        <SubMenu />
  	        </Tabs>
						</Grid>
						<Grid item xs={2}></Grid>
					{showShipsSelector && 
						(<div className={classes.shipsSelector}>
							<ShipsSearchSelector
  	      			key={selectedShip}
    	    			imo={selectedShip}
								ships={props.ships}
								handleChangeShip={handleChangeShip}
								{...props}/>
						</div>)
					}
					{/*showShipsSelector && 
						<ShipsSelector ships={props.ships} handleChangeShip={onShipChanged} {...props}/>
					*/}
					</Grid>
        </Toolbar>)}
      </AppBar> }
      {compact && <IconButton className={classes.compact} onClick={toggleCompact}><ExpandMoreIcon /></IconButton>}
      {changePasswordVisibility && <ChangePassword open={changePasswordVisibility} handleClose={closeChangePassword} />}
    </>
  )
}

export default HeaderView;
