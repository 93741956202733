import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Copyright from 'components/copyright';
import globals from 'utils/globals.js';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '8px 16px',
		fontSize: '12px',
		[theme.breakpoints.down('md')]: {
			fontSize: '0.9vw'
		},
		[theme.breakpoints.down('sm')]:{
			fontSize: 9,
		},
  },
  copyright: {
    fontFamily: 'Esphimere',
    color: '#fff',
    textAlign: 'left',
    '& a': {
      color: theme.palette.text.main,
    },
  },
  version: {
    textAlign: 'right',
  }
}));

const FooterView = (props) => {
  const classes = useStyles();
  
  return (
    <footer>
      <Grid container justify="center" className={classes.container}>
        <Grid item xs={8} className={classes.copyright}>
          <Copyright />
        </Grid>
        <Grid item xs={4} className={classes.version}>
          Version {globals.version}
        </Grid>
      </Grid>
    </footer>
  )
}

export default FooterView;
