//Thanks to Nicolas Gallagher from Twitter Lite
//http://nicolasgallagher.com/redux-modules-and-code-splitting/
export class ReducerRegistry {
  constructor() {
    this._emitChange = null;
    this._reducers = {};
  }
  
  getReducers() {
    return { ...this._reducers };
  }
  
  register(name, reducer) {
    this._reducers = { ...this._reducers, [name]: reducer };
    if (this._emitChange) {
      this._emitChange(this.getReducers());
    }
  }

  setChangeListener(listener) {
    this._emitChange = listener;
  }
  
  registers(reducers) {
    for (let [key, value] of Object.entries(reducers)) {
      this.register(key, value);
    }
  }
  
  fromModule(name, key = 'default', path = "./") {
    let module = require('' + path + name)[key];
    this.registers(module);
  }
};

const reducerRegistry = new ReducerRegistry();
export default reducerRegistry;
