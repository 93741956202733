import { connect } from 'react-redux';
import ThemeProviderComponent from './theme-provider-component.js';

const mapStateToProps = (state, ownProps) => ({
  payload: state.payload.payload,
});

const mapDispatchToProps = dispatch => ({});

const ThemeProvider = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemeProviderComponent);

export default ThemeProvider;
