import shipReducers from './ship.js';
import userReducers from './user.js';
import circlesReducers from './circles.js';
import subsystemsReducers from './subsystems.js';

const reducers = {
  ...shipReducers,
  ...userReducers,
  ...circlesReducers,
  ...subsystemsReducers,
};

export default reducers;
