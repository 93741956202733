import React from 'react';

import Vessels from 'assets/images/icons/vessels.png';
import Alerts from 'assets/images/icons/live_menu.png';
import System from 'assets/images/icons/system_menu.png';
import Data from 'assets/images/icons/data_menu.png';
import Operations from 'assets/images/icons/operations_menu.png';
import Analytics from 'assets/images/icons/analytics_submenu.png';
import Users from 'assets/images/icons/users_menu.png';
import Overview from 'assets/images/icons/overview_menu.png';
import FleetMonitor from 'assets/images/icons/fleet_monitor.png';
import Collections from 'assets/images/icons/collections_menu.png';
import EdgeStatus from 'assets/images/icons/edge_status_submenu.png';
import Quality from 'assets/images/icons/quality_submenu.png';
import Mapping from 'assets/images/icons/mapping_submenu.png';
import TimeMachine from 'assets/images/icons/time_machine_submenu.png';
import Geofence from 'assets/images/icons/geofence_submenu.png';
import ZoomIn from 'assets/images/icons/zoom_in_w.png';
import ZoomOut from 'assets/images/icons/zoom_out_w.png';
import Download from 'assets/images/icons/downloads.png';
import Reports from 'assets/images/icons/reports.png';

const width = 24
const height = width

export const AlertsIcon = () =>  <img height={width} src={Alerts} />
export const SystemIcon = () =>  <img height={width} src={System} />
export const DataIcon = () =>  <img height={width} src={Data} />
export const OperationsIcon = () =>  <img height={width} src={Operations} />
export const AnalyticsIcon = () =>  <img height={width} src={Analytics} />
export const UsersIcon = () =>  <img height={width} src={Users} />
export const OverviewIcon = () =>  <img height={width} src={Overview} />
export const FleetMonitorIcon = () =>  <img height={width} src={FleetMonitor} />
export const CollectionsIcon = () =>  <img width={width} src={Collections} />
export const VesselsIcon = () =>  <img height={width} src={Vessels} />
export const EdgeStatusIcon = () =>  <img height={width} src={EdgeStatus} />
export const QualityIcon = () =>  <img height={width} src={Quality} />
export const MappingIcon = () =>  <img height={width} src={Mapping} />
export const TimeMachineIcon = () =>  <img height={width} src={TimeMachine} />
export const GeofenceIcon = () =>  <img height={width} src={Geofence} />

export const ZoomInMapIcon = ({size=24}) => <img height={size} src={ZoomIn} />
export const ZoomOutMapIcon = ({size=24}) => <img height={size} src={ZoomOut} />

export const ReportsIcon = ({size=24}) => <img height={size} src={Reports} />
export const DownloadIcon = ({size=24}) => <img height={size} src={Download} />
