import { createReducer } from './utils.js';
import { User } from 'actions';

const initialAuthState = {
  authenticated: false,
  user: undefined,
  token: undefined,
  pending: false,
  error: undefined,
  lastResponse: undefined,
}

const initialAPIState = {
  pendingRequests: 0,
  generic: {
    pending: false,
    error: undefined, 
    response: undefined,
  }
}

const initialPayloadState = {
  pending: false,
  error: undefined,
  payload: undefined,
}

const initialRoutePathState = {
  pending: false,
  error: undefined,
  routePath: undefined,
}

const initialUserState = {
  pending: false,
  error: undefined,
  user: undefined,
}

const loginRequest = (state, action) => {
  return {
    ...state,
    pending: true,
    user: action.user,
    error: undefined,
  };
}

const loginSuccess = (state, action) => {
  return {
    ...state,
    authenticated: true,
    user: action.auth.user,
    token: action.auth.token,
    pending: false,
    error: undefined,
  };
}

const loginFailure = (state, action) => {
  return {
    ...initialAuthState,
    error: action.error,
  };
}

const logout = (state, action) => ({})

const apiRequest = (state, action) => {
  return {
    ...state,
    pendingRequests: state.pendingRequests+1,
    [action.data.key]: {
      error: undefined,
      response: undefined,
      ...state[action.data.key],
      pending: true,
    }
  }
}

const apiSuccess = (state, action) => {
  return {
    ...state,
    pendingRequests: state.pendingRequests-1,
    [action.data.key]: {
      ...state[action.data.key],
      response: action.data.response,
      pending: false,
      error: undefined,
    }
  }
}

const apiFailure = (state, action) => {
  return {
    ...state,
    pendingRequests: state.pendingRequests-1,
    [action.data.key]: {
      ...state[action.data.key],
      response: undefined,
      pending: false,
      error: action.data.response,
    }
  }
}

const payloadRequest = (state, action) => {
  return {
    ...state,
    pending: true,
  }
}

const payloadSuccess = (state, action) => {
//	if(!action.data.payload) return {}
  return {
    ...state,
    payload: JSON.parse(action.data.payload),
    pending: false,
    error: undefined,
  }
}

const payloadFailure = (state, action) => {
  return {
    ...state,
    payload: undefined,
    pending: false,
    error: action.error,
  }
}

const routePathRequest = (state, action) => {
  return {
    ...state,
    pending: true,
  }
}

const routePathSuccess = (state, action) => {
  return {
    ...state,
    routePath: action.data,
    pending: false,
    error: undefined,
  }
}

const routePathFailure = (state, action) => {
  return {
    ...state,
    routePath: undefined,
    pending: false,
    error: action.error,
  }
}

const setPayloadRequest = (state, action) => {
  return {
    ...state,
    payload: action.data,
    pending: true,
    error: undefined,
  }
}

const setPayloadSuccess = (state, action) => {
  return {
    ...state,
    pending: false,
    error: undefined,
  }
}

const setPayloadFailure = (state, action) => {
  return {
    ...state,
    pending: false,
    error: action.error,
  }
}

const resetPayload = (state, action) => ({})

const resetApi = (state, action) => initialAPIState;/*{
  return {
    ...state,
    generic: {
      pending: false,
      error: undefined,
      response: undefined,
    }
  }
}*/

const userRequest = (state, action) => {
  return {
    ...state,
    pending: true,
  }
}

const userSuccess = (state, action) => {
  return {
    ...state,
    user: action.data.user,
    pending: false,
    error: undefined,
  }
}

const userFailure = (state, action) => {
  return {
    ...state,
    user: undefined,
    pending: false,
    error: action.error,
  }
}

const reducers = {
  authentication: createReducer(initialAuthState, {
    [User.LOGIN_REQUEST]: loginRequest,
    [User.LOGIN_SUCCESS]: loginSuccess,
    [User.LOGIN_FAILURE]: loginFailure,
    [User.LOGOUT]: logout,
  }),
  
  api: createReducer(initialAPIState, {
    [User.API_REQUEST]: apiRequest,
    [User.API_SUCCESS]: apiSuccess,
    [User.API_FAILURE]: apiFailure,
    
    [User.RESET_API]: resetApi,
  }),
  
  payload: createReducer(initialPayloadState, {
    [User.PAYLOAD_REQUEST]: payloadRequest,
    [User.PAYLOAD_SUCCESS]: payloadSuccess,
    [User.PAYLOAD_FAILURE]: payloadFailure,
    
    [User.SET_PAYLOAD_REQUEST]: setPayloadRequest,
    [User.SET_PAYLOAD_SUCCESS]: setPayloadSuccess,
    [User.SET_PAYLOAD_FAILURE]: setPayloadFailure,
    
    [User.RESET_PAYLOAD]: resetPayload,
  }),
  
  routePath: createReducer(initialRoutePathState, {
    [User.ROUTEPATH_REQUEST]: routePathRequest,
    [User.ROUTEPATH_SUCCESS]: routePathSuccess,
    [User.ROUTEPATH_FAILURE]: routePathFailure,
  }),
  
  user: createReducer(initialUserState, {
    [User.USER_REQUEST]: userRequest,
    [User.USER_SUCCESS]: userSuccess,
    [User.USER_FAILURE]: userFailure,
  }),
};

export default reducers;
